* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: white;
}

/* #root {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
} */

.cell {
  position: relative;
  background-size: cover;
  /* left:5%; */
  /* top:5%; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #777777;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 60px -10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s;
  font-size: 10px;
  line-height: 10px;
}

.default {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
}

.cell:hover {
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.3);
}

.shuffle {
  position: absolute;
  top: 40px;
  left: 40px;
  padding: 0px 20px 0px 20px;
  width: auto;
  height: auto;
  background: white;
  border: solid 2px #ffaad4;
  border-radius: 7px;
  color: #ffaad4;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 100;
  line-height: 45px;
}

.shuffle:hover {
  background: #f9f9f9;
}

.details {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: #ffffffa0; */
  color: white;
  padding: 40px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.details h1 {
  color: #ca6a9a;
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  max-width: 250px;
}

.details p {
  color: thistle;
  font-size: 26px;
  line-height: 26px;
  margin: 0;
  padding-top: 15px;
}

.circle {
  position: absolute;
  top: calc(50% - 140px);
  left: calc(50% - 140px);
  width: 280px;
  height: 280px;
  border-radius: 50%;
  box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 26px;
  color: #777777;
}

.gridmain {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.gridmain .header {
  padding: 0px 0px 0px 0px;
  height: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  margin-bottom: 0px !important;
}

.grid {
  flex: 1;
}

.ant-btn-primary {
  background-color: #ca6a9a !important;
  border-color: #ca6a9a !important;
}
