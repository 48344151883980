.login {
  background-image: url(../img/backImage/urban-438393_1920.jpg);
  /* z-index: -1; */

  &:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    // mkty
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.logout {
  background-image: url(../img/backImage/urban-438393_1920.jpg);
  /* z-index: -1; */

  &:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    // mkty
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.numberbutton {
  margin-bottom: 0;
  padding: 0;
  width: 30px;
  height: 30px;
}

.has-danger .css-yk16xz-control {
  background-color: #ffc0a4;
  border: 1px solid #ef8157;
  color: #ef8157;
}

.span-style {
  margin-right: 1rem;
}

.button-style {
  left: calc(50% - calc(1rem / 2));
}

/* .af .ax {
    border-radius: 20px;
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
    transition-duration: 0.25s;
    transition-property: border;
    border-style: solid;
    border-width: 2px;
    width: 100%;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    line-height: 20px;
    font-weight: normal;
    font-size: 12px;
    font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
  } */

.select-number {
  margin: 0;
  padding: 0;
}
.select-number .select-number-item {
  margin: 0;
  padding: 0;
  list-style: none;
}
.select-number .select-number-item input {
  padding: 3px;
  text-align: center;
}
@media (min-width: 576px) {
  .select-number .select-number-item input {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    flex-flow: column;
    vertical-align: top;
    background-color: transparent;
    width: 48px;
    height: 48px;
  }
  .select-number .select-number-item input[disabled] {
    cursor: default;
    background-color: #e3e3e3;
  }
  .select-number .select-number-item input[readonly] {
    cursor: default;
  }
}
@media (max-width: 575.98px) {
  .select-number .select-number-item input {
    font-size: 0.75rem;
  }
  .select-number-item .form-control {
    width: 26px;
    background-color: transparent;
  }
}
.select-number .select-number-item.select-number-lucky input {
  border-color: #000;
}

.wizard-plain .card-wizard {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.table-responsive .error {
  /* background-color: #ffc0a4; */
  /* border: 1px solid #ef8157; */
  color: #ef8157;
}

/* .alert-with-icon  {
    z-index: 5001;
  } */

.rna-container {
  z-index: 9999;
  position: fixed;
}

.flag-container .selected-flag:focus {
  outline: 0;
}

.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
}

.Countdown-col {
  display: inline-block;
  /* background-color: blue; */
  margin: 2px;
  border-radius: 0.16rem;
  background: rgba(255, 255, 255, 0.13);

  padding-bottom: 5px;
  /* width: 8vw; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 80px; */
}
/* @media (min-width: 1200px) {
    .Countdown-col {
      width: 130px; 
    }
  } */

/* .Countdown-col-element {
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 2vw;
    color: white;
  } */
/* 
  .Countdown-col-element strong {
    font-size: 4vw;
    color: white;
  } */

.pill {
  display: flex;
  font-size: 85%;
  line-height: 1;
}
@media (min-width: 768px) {
  .pill {
    font-size: inherit;
  }
}
.pill span {
  padding: 5px 8px;
  border: 1px solid #e2e5eb;
  border-right-width: 0;
}
.pill span:first-child {
  display: block;
  padding: 5px 12px 4px 15px;
  border-radius: 2rem 0 0 2rem;
}
.pill span:last-child {
  padding: 5px 13px 5px 8px;
  border-right-width: 1px;
  border-radius: 0 2rem 2rem 0;
  color: #fff;
}
.pill.pill-dark {
  color: #4c5661;
}
.pill.pill-dark > :first-child {
  background: #4c5661;
  color: #fff;
}

.MuiTablePagination-root button:focus {
  outline: 0px;
}

.demo-icon {
  width: 20px;
}

.demo-color-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
}

.demo-color-modal {
  position: absolute;
  right: -70px;
  top: 135%;
  z-index: 10;
}

.rdw-storybook-root {
  .rdw-image-modal-header-option {
  }

  .rdw-link-modal {
    height: 270px;
  }
  .rdw-embedded-modal {
    height: 230px;
  }
  .rdw-emoji-modal {
    height: 270px;
  }
}

.content-only {
  .navbar-absolute {
    display: none;
  }

  .footer {
    display: none;
  }
}

// .ck-body-wrapper {
//   z-index: 99999;
// }

// .ck-blurred {
//   z-index: 99999;
// }

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  // border-color: transparent;
  // outline: dotted 1px rgb(102, 97, 91);
  border: 1px solid #9a9a9a;
  box-shadow: none;
  // outline: 0 !important;
  // color: #66615b;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-blurred {
  // border-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 4px;
  // outline: -webkit-focus-ring-color auto 1px;
  // outline: dotted 1px rgb(102, 97, 91)
}

.ck-reset_all {
  .ck.ck-balloon-panel {
    z-index: 99999;
  }
  .ck.ck-button {
    z-index: 99999;
  }
}

#datecalendar{

  input[readonly]{
    // .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
      background-color: #FFFFFF;
      // cursor: not-allowed;
      cursor: pointer;
  // }

  }
}